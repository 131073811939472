import './KnowledgeBaseArticle.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import PuxContent from 'Root/Shared/components/PuxContent/PuxContent'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareSideCardNavigation from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareKnowledgeBaseArticle(first: 1, where: { path: $pagePath }) {
        displayText
        contentItemId
        contentItemVersionId
        knowledgeBaseArticleContent {
          html
        }
        knowledgeBaseArticleTags {
          termContentItems {
            displayText
          }
        }
        localization {
          localizations {
            ... on Orchard_EasySoftwareKnowledgeBaseArticle {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

export interface PageType {
  displayText: string
  contentItemId: string
  contentItemVersionId: string
  knowledgeBaseArticleContent: PuxWysiwygHtmlBodyType
  knowledgeBaseArticleTags: {
    termContentItems: [
      {
        displayText: string
      }
    ]
  }
  localization: {
    localizations: localizedPathType[]
  }
}

export interface KnowledgeBaseArticleType {
  data: {
    orchard: {
      easySoftwareKnowledgeBaseArticle: [PageType]
    }
  }
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    metaTags: PuxMetaTagsType
    knowledgeBaseData: KnowledgeBaseMenuItemType[]
    sideCardWidgets: any
    specificSideCardWidgets: any
    path: string
  }
}

const KnowledgeBaseArticle: FunctionComponent<KnowledgeBaseArticleType> = ({
  data,
  pageContext,
}) => {
  const breadcrumbsData = pageContext.breadcrumbs
  const knowledgeBaseArticleData =
    data.orchard.easySoftwareKnowledgeBaseArticle[0]

  return (
    <Suspense fallback={<></>}>
      <Layout
        localizedPath={knowledgeBaseArticleData.localization?.localizations}
      >
        <PuxMetaTags {...pageContext.metaTags} />
        <div className='Container KnowledgeBaseArticle'>
          <EasySoftwareBreadcrumbs items={breadcrumbsData} />
          <PuxContent
            contentModifier='knowledgeBaseArticle'
            sideContent={
              <EasySoftwareSideCardNavigation
                type='knowledgeBase'
                sideCardNavigationLinks={pageContext.knowledgeBaseData}
              />
            }
            sideCards={
              pageContext.specificSideCardWidgets || pageContext.sideCardWidgets
            }
          >
            <h1>{knowledgeBaseArticleData.displayText}</h1>

            <div className='KnowledgeBaseArticle-tags'>
              {knowledgeBaseArticleData.knowledgeBaseArticleTags?.termContentItems?.map(
                (item) => (
                  <div className='KnowledgeBaseArticle-tag-item'>
                    {item.displayText}
                  </div>
                )
              )}
            </div>
            <PuxWysiwyg
              content={knowledgeBaseArticleData.knowledgeBaseArticleContent}
            />
          </PuxContent>
        </div>
      </Layout>
    </Suspense>
  )
}

export default KnowledgeBaseArticle
