import './CaseStudyDetail.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { PuxKeyValueType } from 'Shared/components/EasySoftwareCaseStudies/EasySoftwareCaseStudyItem'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import { getUrlWithProtocol } from 'Shared/utils/getUrlWithProtocol'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'
import { t } from 'ttag'

export interface CaseStudyType {
  contentType: string
  contentItemId: string
  contentItemVersionId: string
  path: string
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
  caseStudyUrl: string
  caseStudyPerex: PuxWysiwygHtmlBodyType
  caseStudyContent: PuxWysiwygHtmlBodyType
  caseStudyImage: PuxMediaSelectorType
  caseStudyTopImage?: PuxMediaSelectorType
  caseStudyParameters: {
    contentItems: [PuxKeyValueType]
  }
}

export interface CaseStudyDetail extends PageProps {
  data: {
    orchard: {
      easySoftwareCaseStudy: [CaseStudyType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    widgets: {
      caseStudyWidgets: [WidgetPuxSectionType]
    }
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareCaseStudy(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareCaseStudy {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        caseStudyUrl
        caseStudyPerex {
          html
        }
        caseStudyContent {
          html
        }
        caseStudyTopImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        caseStudyImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        caseStudyParameters {
          contentItems {
            ... on Orchard_PuxKeyValue {
              displayText
              contentItemId
              keyValueContent {
                html
              }
            }
          }
        }
      }
    }
  }
`

const CaseStudyDetail: FunctionComponent<CaseStudyDetail> = (props) => {
  const pageData = props.data.orchard.easySoftwareCaseStudy[0]
  const breadCrumbsData = props.pageContext.breadcrumbs
  const widgets = props.pageContext.widgets
  const hasTopImage =
    pageData?.caseStudyTopImage?.mediaSelectorImage?.resizePaths.length > 0

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={pageData.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='CaseStudyDetail'>
          {!hasTopImage && <EasySoftwareBreadcrumbs items={breadCrumbsData} />}
          {hasTopImage && (
            <div className='CaseStudyDetail-topimage'>
              <PuxMediaSelector {...pageData.caseStudyTopImage!} />
              <div className='CaseStudyDetail-title'>
                <h2>{pageData.displayText}</h2>
              </div>
            </div>
          )}
          <div className='CaseStudyDetail-container'>
            {!hasTopImage && (
              <h2 className='CaseStudyDetail-title'>{pageData.displayText}</h2>
            )}
            <div className='CaseStudyDetail-meta'>
              <div className='CaseStudyDetail-image'>
                <PuxMediaSelector {...pageData.caseStudyImage} />
                {pageData.caseStudyUrl && (
                  <div className='CaseStudyDetail-url'>
                    <a
                      href={getUrlWithProtocol(pageData.caseStudyUrl) || ``}
                      target='_blank'
                      rel='noopener'
                    >
                      {t`EasyRedmine.Website`}
                    </a>
                  </div>
                )}
              </div>
              <div className='CaseStudyDetail-parameters'>
                {pageData.caseStudyParameters &&
                  pageData.caseStudyParameters.contentItems &&
                  pageData.caseStudyParameters.contentItems.map((item) => (
                    <div
                      className='CaseStudyDetail-parameter'
                      key={item.contentItemId}
                    >
                      <div className='CaseStudyDetail-label'>
                        {item.displayText}:
                      </div>
                      <div className='CaseStudyDetail-value'>
                        <PuxWysiwyg inline content={item.keyValueContent} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className='CaseStudyDetail-perex perex'>
              <PuxWysiwyg content={pageData.caseStudyPerex} />
            </div>

            <div className='CaseStudyDetail-content'>
              <PuxWysiwyg content={pageData.caseStudyContent} />
            </div>
          </div>
        </div>
        <div className='pux-container pux-container-thin pux-container-widget-builder'>
          <WidgetBuilder widgetBuilderData={widgets?.caseStudyWidgets} />
        </div>
      </Layout>
    </Suspense>
  )
}

export default CaseStudyDetail
