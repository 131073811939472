import Loader from '@componentsfld/core/Loader'
import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from 'Root/Shared/utils/getGraphqlValidationToken'

import PricingPage, { PricingPageType, PricingType } from './PricingPage'

const PreviewPricingPage: FunctionComponent<PricingPageType> = (props) => {
  const pageData = props.data.orchard.easySoftwarePricingPage[0]
  const [completeData, setCompleteData] = React.useState<PricingPageType>()

  const query = `
  {
    easySoftwarePricingPage(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      contentType
      contentItemId
      contentItemVersionId
      path
      displayText
      localization {
        localizations {
          ... on EasySoftwarePricingPage {
            path
            published
            localization {
              culture
            }
          }
        }
      }
      pricingPagePerex {
        html
      }
      pricingPageEnterpriseContent {
        html
      }
      pricingPageCurrencies {
        contentItems {
          ... on EasySoftwarePricingCurrency {
            contentType
            contentItemId
            displayText
            pricingCurrencySymbol
            pricingCurrencyPriceEssential
            pricingCurrencyPriceBusiness
            pricingCurrencyPricePlatform
          }
        }
      }
      pricingPageFeatures {
        contentItems {
          ... on EasySoftwarePricingFeature {
            contentType
            contentItemId
            displayText
            pricingFeatureTooltip
            pricingFeaturePlanPlatform
            pricingFeaturePlanEssential
            pricingFeaturePlanBusiness
          }
        }
      }
      pricingPageAllFeatures {
        contentItems {
          ... on EasySoftwarePricingFeatureCategory {
            displayText
            contentItemId
            contentType
            pricingFeatureCategoryItems {
              contentItems {
                ... on EasySoftwarePricingFeature {
                  displayText
                  contentItemId
                  contentType
                  pricingFeaturePlanBusiness
                  pricingFeaturePlanEnterprise
                  pricingFeaturePlanEssential
                  pricingFeaturePlanPlatform
                  pricingFeatureTooltip
                }
              }
            }
          }
        }
      }
      pricingPageBuyLink {
        internal
        text
        url
      }
    }
  }`

  React.useEffect(async () => {
    const tokenData = await getTokenData()
    return await axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        `query ${query}`.replace(/\n/g, ``),
        {
          headers: {
            'Content-Type': `application/graphql`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: PricingType =
            response.data.data.easySoftwarePricingPage[0]
          const data = { ...props }
          data.data.orchard.easySoftwarePricingPage = [documentData]
          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? (
    <PricingPage {...completeData} />
  ) : (
    <Loader message='Loading Pricing Draft Data' />
  )
}

export default PreviewPricingPage
