import './PuxGallery.scss'

import FsLightbox from 'fslightbox-react'
import React, { FunctionComponent, useState } from 'react'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'

import PuxCarousel from '../PuxCarousel/PuxCarousel'
import PuxGalleryItem, { PuxGalleryItemType } from './PuxGalleryItem'

export interface PuxGalleryType {
  contentItemId?: string
  displayText: string
  galleryList: {
    contentItems: PuxGalleryItemType[]
  }
  galleryItemsToLightbox: boolean
  gallerySlider: boolean
}

const CAROUSEL_MIN_ITEMS = 7

const PuxGallery: FunctionComponent<PuxGalleryType> = (props) => {
  const { width } = useWindowDimensions()
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  })
  const lightboxHandler = (id: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: id,
    })
  }
  const slidesPaths: string[] = []
  const slides = props.galleryList?.contentItems?.map((item, idx) => {
    slidesPaths.push(item.galleryItemImage.resizePaths[0])

    return (
      <PuxGalleryItem
        key={idx}
        id={idx}
        galleryItemImage={item.galleryItemImage}
        galleryLightbox={props.galleryItemsToLightbox}
        galleryLightboxHandler={lightboxHandler}
        alt={item.alt}
      />
    )
  })

  const useCarousel =
    slides?.length >= CAROUSEL_MIN_ITEMS && props.gallerySlider && width > 992

  return (
    <div className='Gallery'>
      {useCarousel ? (
        <div className='Gallery-items'>
          <PuxCarousel
            slidesPerView={4}
            navigation
            autoplay
            contentItemId={props.contentItemId}
            slides={slides}
            breakpoints={{
              1400: {
                slidesPerView: 6,
              },
            }}
          />
        </div>
      ) : (
        <div className='Gallery-static'>
          <div className='Gallery-items'>{slides}</div>
        </div>
      )}
      {props.galleryItemsToLightbox ? (
        <FsLightbox
          sources={slidesPaths}
          type='image'
          toggler={lightboxController.toggler}
          sourceIndex={lightboxController.slide}
        />
      ) : null}
    </div>
  )
}

export default PuxGallery
