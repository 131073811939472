import './KnowledgeBaseCategory.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import EasySoftwareSearch from 'Root/Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareKnowledgeBaseCategory(first: 1, where: { path: $pagePath }) {
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareKnowledgeBaseCategory {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

interface PageType {
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

interface KnowledgeBaseCategoryType {
  data: {
    orchard: {
      easySoftwareKnowledgeBaseCategory: [PageType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    knowledgeBaseData: KnowledgeBaseMenuItemType[]
    sideCardWidgets: any
    specificSideCardWidgets: any
  }
}

const KnowledgeBaseCategory: FunctionComponent<KnowledgeBaseCategoryType> = ({
  data,
  pageContext,
}) => {
  const breadcrumbsData = pageContext.breadcrumbs

  return (
    <Suspense fallback={<></>}>
      <Layout
        localizedPath={
          data.orchard.easySoftwareKnowledgeBaseCategory[0].localization
            ?.localizations
        }
      >
        <PuxMetaTags {...pageContext.metaTags} />
        <div className='Container KnowledgebaseCategory'>
          <EasySoftwareBreadcrumbs items={breadcrumbsData} showAll={true} />
          <div className='KnowledgebaseCategory-header'>
            <h1>
              {data.orchard.easySoftwareKnowledgeBaseCategory[0].displayText}
            </h1>
            <EasySoftwareSearch
              searchType='knowledgebase'
              setLanguageDialogOpen={() => true}
            />
          </div>
          <WidgetBuilder widgetBuilderData={pageContext.widgets.widgetZone} />
        </div>
      </Layout>
    </Suspense>
  )
}

export default KnowledgeBaseCategory
