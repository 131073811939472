import Loader from '@componentsfld/core/Loader'
import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from 'Root/Shared/utils/getGraphqlValidationToken'

import LandingPage, { LandingPageType, LandingType } from './LandingPage'

const PreviewLandingPage: FunctionComponent<LandingPageType> = (props) => {
  const pageData = props.data.orchard.puxLandingPage[0]
  const [completeData, setCompleteData] = React.useState<LandingPageType>()

  const query = `
  {
    puxLandingPage(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      contentType
      contentItemId
      path
      displayText
      landingPageButton {
        text
        url
      }
      landingPageAnchors {
        contentItems {
          ... on EasySoftwareAnchorItem {
            displayText
            contentType
            anchorItemTarget
          }
          ... on EasySoftwareLinkItem {
            contentType
            linkItemTarget {
              internal
              text
              url
            }
          }
        }
      }
    }
  }`

  React.useEffect(async () => {
    const tokenData = await getTokenData()
    return await axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        `query ${query}`.replace(/\n/g, ``),
        {
          headers: {
            'Content-Type': `application/graphql`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: LandingType = response.data.data.puxLandingPage[0]
          const data = { ...props }
          data.data.orchard.puxLandingPage = [documentData]
          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? (
    <LandingPage {...completeData} />
  ) : (
    <Loader message='Loading Landing Page Draft Data' />
  )
}

export default PreviewLandingPage
