export const removeAllParamsWhenUTM = (path: string): void => {
  const url = new URL(path)
  const searchParams = url.searchParams

  if (
    searchParams.toString().includes(`utm_`) ||
    searchParams.toString().includes(`gclid`) ||
    searchParams.toString().includes(`aw_`)
  ) {
    const allParams: string[] = []
    for (const param of searchParams.keys()) {
      if (param.toString().includes(`utm_`) ||
        param.toString().includes(`gclid`) ||
        param.toString().includes(`aw_`)) {
        allParams.push(param)
      }
    }

    allParams.map((param) => {
      searchParams.delete(param)
    })

    return window.location.replace(url.href)
  }

  return
}
