import './PuxImageContent.scss'

import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'
import { t } from 'ttag'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxLightboxGallery from '../PuxLightboxGallery/PuxLightboxGallery'
import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export enum ImageContentButtonAlign {
  LEFT = `left`,
  RIGHT = `right`,
  CENTER = `center`,
}

export interface PuxImageContentType {
  imageContentText: PuxWysiwygHtmlBodyType
  imageContentCollapseContent: PuxWysiwygHtmlBodyType
  imageContentImagePosition: string
  imageContentTextAlign: string
  imageContentButtonAlign: ImageContentButtonAlign
  imageContentGallery: PuxMediaSelectorType
  puxMediaSelector: PuxMediaSelectorType
  puxButton: PuxButtonType
  puxVideo: PuxVideoType
}

const PuxImageContent: FunctionComponent<PuxImageContentType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  const { width } = useWindowDimensions()

  const ImageContentGallery = (
    <PuxLightboxGallery
      {...props.imageContentGallery}
      disableLazyload
      width={160}
      height={80}
    />
  )

  const className = [`ImageContent`]

  if (props.imageContentImagePosition) {
    className.push(props.imageContentImagePosition)
  }

  if (props.imageContentTextAlign) {
    className.push(props.imageContentTextAlign)
  }

  return (
    <div className={className.join(` `)}>
      <div className='ImageContent-image'>
        <div className='ImageContent-mainImage'>
          <PuxMediaSelector
            {...props.puxMediaSelector}
            width={720}
            height={400}
          />
          {props.puxVideo?.videoYTID?.html && (
            <PuxVideo
              videoStructuredDataContentUrl={
                props.puxVideo?.videoStructuredDataContentUrl
              }
              videoStructuredDataDescription={
                props.puxVideo?.videoStructuredDataDescription
              }
              videoStructuredDataName={props.puxVideo?.videoStructuredDataName}
              videoStructuredDataUploadDate={
                props.puxVideo?.videoStructuredDataUploadDate
              }
              videoThumbnail={props.puxVideo?.videoThumbnail}
              videoYTID={props.puxVideo?.videoYTID}
            />
          )}
          {width > 767 && (
            <Collapse isOpened={isOpened}>{ImageContentGallery}</Collapse>
          )}
        </div>
      </div>
      <div className='ImageContent-content'>
        <PuxWysiwyg content={props.imageContentText} />
        {props.puxButton.buttonLink.text[0] && (
          <div
            className={`ImageContent-button ${props.imageContentButtonAlign
                ? `button-` + props.imageContentButtonAlign
                : ``
              }`}
          >
            <PuxButton {...props.puxButton} />
          </div>
        )}
        {(props.imageContentCollapseContent?.html ||
          props.imageContentGallery?.mediaSelectorImage?.resizePaths[0]) && (
            <>
              <Collapse
                initialStyle={{ height: `0px`, overflow: `hidden` }}
                isOpened={isOpened}
              >
                {props.imageContentCollapseContent?.html && (
                  <PuxWysiwyg content={props.imageContentCollapseContent} />
                )}
                {width < 768 && ImageContentGallery}
              </Collapse>
              <div className={`ImageContent-moreLess`}>
                <button
                  className={`Button Button--link Button--medium${isOpened ? ` is-active` : ``
                    }`}
                  onClick={() => setIsOpened(!isOpened)}
                >
                  {isOpened
                    ? t`Redmine.Function.LessInfo`
                    : t`Redmine.Function.MoreInfo`}
                </button>
              </div>
            </>
          )}
      </div>
    </div>
  )
}

export default PuxImageContent
