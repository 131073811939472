import Loader from '@componentsfld/core/Loader'
import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from 'Root/Shared/utils/getGraphqlValidationToken'

import FunctionDetail, {
  FunctionDetailType,
  FunctionType,
} from './FunctionDetail'

const PreviewFunctionDetail: FunctionComponent<FunctionDetailType> = (
  props
) => {
  const pageData = props.data.orchard.easySoftwareFunction[0]
  const [completeData, setCompleteData] = React.useState<FunctionDetailType>()

  const query = `
  {
    easySoftwareFunction(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      contentType
      path
      contentItemId
      displayText
      functionPerex {
        html
      }
      functionContent {
        html
      }
      functionImage {
        mediaSelectorImage {
          resizePaths(width: 1920, mode: "Min", quality: 75)
          resizePathsMobile(width: 768, mode: "Min", quality: 75)
          resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          texts
        }
      }
      functionGallery {
        mediaSelectorImage {
          resizePaths(width: 1920, mode: "Min", quality: 75)
          resizePathsMobile(width: 768, mode: "Min", quality: 75)
          resizePathsTablet(width: 1200, mode: "Min", quality: 75)
        }
      }
      puxVideo {
        videoStructuredDataContentUrl
        videoStructuredDataDescription
        videoStructuredDataName
        videoStructuredDataUploadDate
        videoYTID {
          html
        }
      }
    }
  }`

  React.useEffect(async () => {
    const tokenData = await getTokenData()
    return await axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        `query ${query}`.replace(/\n/g, ``),
        {
          headers: {
            'Content-Type': `application/graphql`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: FunctionType =
            response.data.data.easySoftwareFunction[0]
          const data = { ...props }
          data.data.orchard.easySoftwareFunction = [documentData]
          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? (
    <FunctionDetail {...completeData} />
  ) : (
    <Loader message='Loading Function Draft Data' />
  )
}

export default PreviewFunctionDetail
