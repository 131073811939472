export const localeConfig: configType = {
  EasyRedmineGatsby: {
    kr: {
      locale: `kr`,
      urlPrefix: `kr`,
      isoCode: `ko-KR`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    jp: {
      locale: `jp`,
      urlPrefix: `jp`,
      isoCode: `ja-JP`,
      mautic: {
        src: `https://mautic.easyredmine.jp/media/js/mautic-form.js`,
        domain: `https://mautic.easyredmine.jp`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    ru: {
      locale: `ru`,
      urlPrefix: `ru`,
      isoCode: `ru-RU`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    br: {
      locale: `br`,
      urlPrefix: `br`,
      isoCode: `pt-BR`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    es: {
      locale: `es`,
      urlPrefix: `es`,
      isoCode: `es-ES`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    fr: {
      locale: `fr`,
      urlPrefix: `fr`,
      isoCode: `fr-FR`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    de: {
      locale: `de`,
      urlPrefix: `de`,
      isoCode: `de-DE`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
    en: {
      locale: `en`,
      urlPrefix: ``,
      isoCode: `en-GB`,
      mautic: {
        src: `https://mautic2018.easyredmine.com/media/js/mautic-form.js`,
        domain: `https://mautic2018.easyredmine.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-readmine.puxdesign.net`,
        stage: `https://dev1.easysoftware.client.puxdesign.cz`,
        rdn: `https://rdn.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyredmine.com`,
      },
      GTM: `GTM-NKJSRFS`,
    },
  },
  EasyProjectGatsby: {
    ru: {
      locale: `ru`,
      urlPrefix: `ru`,
      isoCode: `ru-RU`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    de: {
      locale: `de`,
      urlPrefix: `de`,
      isoCode: `de-DE`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    fr: {
      locale: `fr`,
      urlPrefix: `fr`,
      isoCode: `fr-FR`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    es: {
      locale: `es`,
      urlPrefix: `es`,
      isoCode: `es-ES`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    it: {
      locale: `it`,
      urlPrefix: `it`,
      isoCode: `it-IT`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
    hu: {
      locale: `hu`,
      urlPrefix: ``,
      isoCode: `hu-HU`,
      mautic: {
        src: `https://mautic.easyproject.hu/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.hu`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev3-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-3-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.hu`,
      },
      GTM: `GTM-NFPB4Z9`,
    },
    cs: {
      locale: `cs`,
      urlPrefix: ``,
      isoCode: `cs-CZ`,
      mautic: {
        src: `https://mautic2017.easyproject.cz/media/js/mautic-form.js`,
        domain: `https://mautic2017.easyproject.cz`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev2-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-2-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.cz`,
      },
      GTM: `GTM-5SQG2G5`,
    },
    en: {
      locale: `en`,
      urlPrefix: ``,
      isoCode: `en-GB`,
      mautic: {
        src: `https://mautic.easyproject.com/media/js/mautic-form.js`,
        domain: `https://mautic.easyproject.com`,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-project.puxdesign.net`,
        stage: `http://dev1-ep.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-1-ep.easysoftware.client.puxdesign.cz`,
        production: `https://www.easyproject.com`,
      },
      GTM: `GTM-K75449W`,
    },
  },
  EasySoftwareGatsby: {
    cs: {
      locale: `cs`,
      urlPrefix: `cs`,
      isoCode: `cs-CZ`,
      mautic: {
        src: ``,
        domain: ``,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-software.puxdesign.net`,
        stage: `http://dev1-es.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-es.easysoftware.client.puxdesign.cz`,
        production: `https://www.easysoftware.com`,
      },
      GTM: `GTM-NPCRBNG`,
    },
    en: {
      locale: `en`,
      urlPrefix: ``,
      isoCode: `en-GB`,
      mautic: {
        src: ``,
        domain: ``,
      },
      domains: {
        development: `http://localhost:8000`,
        test: `http://easy-software.puxdesign.net`,
        stage: `http://dev1-es.easysoftware.client.puxdesign.cz`,
        rdn: `http://rdn-es.easysoftware.client.puxdesign.cz`,
        production: `https://www.easysoftware.com`,
      },
      GTM: `GTM-NPCRBNG`,
    },
  },
};

export const automaticLocalizationConfig: automaticLocalizationConfigType = {
  EasyRedmineGatsby: [
    {
      id: `bg`,
      isoCode: 'bg-BG',
      url: `https://bg.easyredmine.com/`,
    },
    {
      id: `cs`,
      isoCode: 'cs-CZ',
      url: `https://cs.easyredmine.com/`,
    },
    {
      id: `dk`,
      isoCode: 'da-DK',
      url: `https://da.easyredmine.com/`,
    },
    {
      id: `gr`,
      isoCode: 'el-GR',
      url: `https://el.easyredmine.com/`,
    },
    {
      id: `hu`,
      isoCode: 'hu-HU',
      url: `https://hu.easyredmine.com/`,
    },
    {
      id: `il`,
      isoCode: 'he-IL',
      url: `https://iw.easyredmine.com/`,
    },
    {
      id: `it`,
      isoCode: 'it-IT',
      url: `https://it.easyredmine.com/`,
    },
    {
      id: `pl`,
      isoCode: 'pl-PL',
      url: `https://pl.easyredmine.com/`,
    },
    {
      id: `se`,
      isoCode: 'sv-SE',
      url: `https://sv.easyredmine.com/`,
    },
    {
      id: `tr`,
      isoCode: 'tr-TR',
      url: `https://tr.easyredmine.com/`,
    },
  ],
  EasyProjectGatsby: [
    {
      id: `bg`,
      isoCode: 'bg-BG',
      url: `https://bg.easyproject.com/`,
    },
    {
      id: `dk`,
      isoCode: 'da-DK',
      url: `https://da.easyproject.com/`,
    },
    {
      id: `nl`,
      isoCode: 'nl-NL',
      url: `https://nl.easyproject.com/`,
    },
    {
      id: `gr`,
      isoCode: 'el-GR',
      url: `https://el.easyproject.com/`,
    },
    {
      id: `il`,
      isoCode: 'he-IL',
      url: `https://iw.easyproject.com/`,
    },
    {
      id: `jp`,
      isoCode: 'ja-JP',
      url: `https://ja.easyproject.com/`,
    },
    {
      id: `kr`,
      isoCode: 'ko-KR',
      url: `https://ko.easyproject.com/`,
    },
    {
      id: `no`,
      isoCode: 'no-NO',
      url: `https://no.easyproject.com/`,
    },
    {
      id: `pl`,
      isoCode: 'pl-PL',
      url: `https://pl.easyproject.com/`,
    },
    {
      id: `tr`,
      isoCode: 'tr-TR',
      url: `https://tr.easyproject.com/`,
    },
  ],
};

export const ptConfigs: PTConfigs = {
  EasySoftwarePage: {
    codeName: `easySoftwarePage`,
    componentName: `EasySoftwarePage`,
    zones: [`widgetZone`],
  },
  PuxHomePage: {
    codeName: `puxHomePage`,
    componentName: `PuxHomePage`,
    zones: [`widgetZone`],
  },
  EasySoftwareArticle: {
    codeName: `easySoftwareArticle`,
    componentName: `PreviwArticlePage`,
    zones: [`articleWidgets`],
  },
  EasySoftwareCaseStudy: {
    codeName: `easySoftwareCaseStudy`,
    componentName: `PreviewCaseStudyDetail`,
    zones: [`caseStudyWidgets`],
  },
  EasySoftwareFunction: {
    codeName: `easySoftwareFunction`,
    componentName: `PreviewFunctionDetail`,
    zones: [`widgetZone`],
  },
  EasySoftwareNews: {
    codeName: `easySoftwareNews`,
    componentName: `PreviewNewsDetail`,
    zones: [`widgetZone`],
  },
  EasySoftwareCategoryPage: {
    codeName: `easySoftwareCategoryPage`,
    componentName: `PreviewCategoryPage`,
    zones: [`categoryWidgets`],
  },
  PuxLandingPage: {
    codeName: `puxLandingPage`,
    componentName: `PreviewLandingPage`,
    zones: [`landingWidgets`],
  },
  EasySoftwareTrainingCenter: {
    codeName: `easySoftwareTrainingCenter`,
    componentName: `PreviewCategoryPage`,
    zones: [`topWidgetZone`, `bottomWidgetZone`],
  },
  EasySoftwarePricingPage: {
    codeName: `easySoftwarePricingPage`,
    componentName: `PreviewPricingPage`,
    zones: [`midWidgetZone`, `bottomWidgetZone`],
  },
  EasySoftwareKnowledgeBaseArticle: {
    codeName: `easySoftwareKnowledgeBaseArticle`,
    componentName: `PreviewKnowledgeBaseArticle`,
    zones: [],
  },
  EasySoftwareFaq: {
    codeName: `easySoftwareFaq`,
    componentName: `PreviewFaqDetail`,
    zones: [],
  },
};

export const searchConfig: SearchConfig = {
  EasyRedmineGatsby: {
    apiKey: `AIzaSyDe18pSt4eqcHdG93c1Hjfo08ZqbcqMF5Y`,
    apiUrl: `https://www.googleapis.com/customsearch/v1/siterestrict`,
    localizedOptions: {
      en: {
        index: `010400418469978324416:rc5-y9afa54`,
        knowledgeBaseUrl: `www.easyredmine.com/documentation-of-easy-redmine`,
      },
      ru: {
        index: `010400418469978324416:4afplrturkh`,
        knowledgeBaseUrl: ``,
      },
      fr: {
        index: `010400418469978324416:52pascn3u1l`,
        knowledgeBaseUrl: ``,
      },
      kr: {
        index: `87799f285b589e63e`,
        knowledgeBaseUrl: ``,
      },
      br: {
        index: `010400418469978324416:6abhxoptsd2`,
        knowledgeBaseUrl: ``,
      },
      de: {
        index: `010400418469978324416:zjrhuvijnse`,
        knowledgeBaseUrl: ``,
      },
      jp: {
        index: `da56654bf06e36b86`,
        knowledgeBaseUrl: ``,
      },
      es: {
        index: `010400418469978324416:lrh9lm7nnik`,
        knowledgeBaseUrl: ``,
      },
    },
  },
  EasyProjectGatsby: {
    apiKey: `AIzaSyDe18pSt4eqcHdG93c1Hjfo08ZqbcqMF5Y`,
    apiUrl: `https://www.googleapis.com/customsearch/v1/siterestrict`,
    localizedOptions: {
      en: {
        index: `010400418469978324416:c3zpnvt5pdi`,
        knowledgeBaseUrl: `www.easyproject.com/documentation`,
      },
      cs: {
        index: `010400418469978324416:6efi92xfojy`,
        knowledgeBaseUrl: `www.easyproject.cz/dokumentace`,
      },
      hu: {
        index: `010400418469978324416:zfi2oj6w2m8`,
        knowledgeBaseUrl: `www.easyproject.hu/dokumentacio`,
      },
      de: {
        index: `010400418469978324416:ah4qm8syj1k`,
        knowledgeBaseUrl: ``,
      },
      es: {
        index: `010400418469978324416:km4nvd9q9vp`,
        knowledgeBaseUrl: ``,
      },
      fr: {
        index: `010400418469978324416:p0na8zoezx2`,
        knowledgeBaseUrl: ``,
      },
      it: {
        index: `010400418469978324416:u54st4ayaov`,
        knowledgeBaseUrl: ``,
      },
      ru: {
        index: `010400418469978324416:h1hey5wzlso`,
        knowledgeBaseUrl: ``,
      },
    },
  },
};

export interface automaticLocalizationConfigType {
  [site: string]: automaticLocalizationConfigItemType[];
}
export interface automaticLocalizationConfigItemType {
  id: string;
  isoCode: string;
  url: string;
}
export interface configType {
  [site: string]: siteConfigType;
}
export interface siteConfigType {
  [lang: string]: langConfig;
}

export interface langConfig {
  locale: string;
  urlPrefix: string;
  isoCode: string;
  mautic: mauticConfig;
  domains: langDomain;
  GTM: string;
}

export interface langDomain {
  [domain: string]: string;
}

export interface mauticConfig {
  src: string;
  domain: string;
}

interface SearchConfig {
  [project: string]: {
    apiKey: string;
    apiUrl: string;
    localizedOptions: SearchLocalizedOptions;
  };
}

interface SearchLocalizedOptions {
  [locale: string]: {
    index: string;
    knowledgeBaseUrl: string;
  };
}

interface PTConfig {
  codeName: string;
  componentName: string;
  zones: string[];
}

interface PTConfigs {
  [key: string]: PTConfig;
}
