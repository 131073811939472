import './EasySoftwareSearchResult.scss'

import React from 'react'

export interface EasySoftwareSearchResultItem {
  searchResultType?: 'header' | 'headerRespo' | 'knowledgebase'
  displayLink?: string
  htmlFormattedUrl?: string
  htmlSnippet?: string
  link?: string
  snippet?: string
  title?: string
  key: number
}

const rootClass = `search-result-item`

const EasySoftwareSearchResult: React.FunctionComponent<EasySoftwareSearchResultItem> = ({
  searchResultType,
  htmlFormattedUrl,
  htmlSnippet,
  link,
  title,
}) => {
  switch (searchResultType) {
    case `knowledgebase`:
      return (
        <li className={`${rootClass}`}>
          <a href={link ? link : `#`}>
            <div className={`${rootClass}-title`}>{title}</div>
          </a>
        </li>
      )
    default:
      return (
        <li className={`${rootClass}`}>
          <a href={link ? link : `#`}>
            <div className={`${rootClass}-title`}>{title}</div>
            <div
              className={`${rootClass}-description`}
              dangerouslySetInnerHTML={{
                __html: htmlSnippet ? htmlSnippet : ``,
              }}
            ></div>
            <div
              className={`${rootClass}-url`}
              dangerouslySetInnerHTML={{
                __html: htmlFormattedUrl ? htmlFormattedUrl : ``,
              }}
            ></div>
          </a>
        </li>
      )
  }
}

/*
    TODO: change a tag to Link tag after indexing our site
*/

export default EasySoftwareSearchResult
