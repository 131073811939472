import './ChangeLogPage.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent, lazy, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { ChangeLogDataType } from 'Shared/utils/getChangeLogData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

const ChangeLogTable = lazy(() => import(`./ChangeLogTable`))

export interface ChangeLogType {
  contentType: string
  path: string
  contentItemId: string
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

export interface ChangeLogPageType extends PageProps {
  data: {
    orchard: {
      easySoftwareChangeLog: [ChangeLogType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    changeLog: ChangeLogDataType
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareChangeLog(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareChangeLog {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

const ChangeLogPage: FunctionComponent<ChangeLogPageType> = (props) => {
  const data = props.data.orchard.easySoftwareChangeLog[0]
  const breadcrumbsData = props.pageContext.breadcrumbs

  const buildChangeLogTable = (version: string, key: number) => {
    const dataByVersion =
      props.pageContext.changeLog.data?.filter(
        (item) => item.version === version
      ) ?? null

    if (dataByVersion !== null) {
      return (
        <Suspense fallback={<></>}>
          <ChangeLogTable data={dataByVersion} version={version} key={key} />
        </Suspense>
      )
    }

    return null
  }

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={data.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='ChangeLog'>
          <EasySoftwareBreadcrumbs items={breadcrumbsData} />
          <div className='Container'>
            <PuxContent sideCards={props.pageContext.sideCardWidgets}>
              <h1 className='ChangeLog-title'>{data.displayText}</h1>

              <div className='ChangeLog-grid'>
                {props.pageContext.changeLog.versions?.map((version, idx) =>
                  buildChangeLogTable(version, idx)
                )}
              </div>
            </PuxContent>
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default ChangeLogPage
