import './PuxGallery.scss'
import React, { FunctionComponent } from 'react'
import PuxLinkGallerySet from './PuxLinkGallerySet'


export interface PuxLinkGalleryWrapperType {
  contentItemId?: string
  linkGalleryWrapperItemsSet: {
    contentItems: any
  }
  linkGalleryWrapperSlider: boolean
}


const PuxLinkGalleryWrapper: FunctionComponent<PuxLinkGalleryWrapperType> = (
  props
) => (
  <div className='Gallery'>
      {props.linkGalleryWrapperItemsSet &&
        props.linkGalleryWrapperItemsSet.contentItems &&
        props.linkGalleryWrapperItemsSet.contentItems.map((item) => (
          <PuxLinkGallerySet key={item.contentItemId} {...item} 
          linkGalleryWrapperSlider={props.linkGalleryWrapperSlider}/>
        ))}
  </div>
)

export default PuxLinkGalleryWrapper
