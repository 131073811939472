import './CategoryPage.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface CategoryType {
  contentType: string
  path: string
  contentItemId: string
  contentItemVersionId: string
  displayText: string
  categoryPageDescription: PuxWysiwygHtmlBodyType
  categoryPageTitleAlign: '' | 'u-textCenter'
  localization: {
    localizations: localizedPathType[]
  }
}

export interface CategoryPageType extends PageProps {
  data: {
    orchard: {
      easySoftwareCategoryPage: [CategoryType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      categoryWidgets: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareCategoryPage(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareCategoryPage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        categoryPageDescription {
          html
        }
        categoryPageTitleAlign
      }
    }
  }
`

const CategoryPage: FunctionComponent<CategoryPageType> = (props) => {
  const data = props.data.orchard.easySoftwareCategoryPage[0]
  const widgets = props.pageContext.widgets
  const breadCrumbsData = props.pageContext.breadcrumbs
  const titleClass = ['CategoryPage-title']

  if (data?.categoryPageTitleAlign) {
    titleClass.push(data.categoryPageTitleAlign)
  }

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={data.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='CategoryPage'>
          <EasySoftwareBreadcrumbs items={breadCrumbsData} />
          <div className='Container'>
            <div className='CategoryPage-header'>
              <h1 className={titleClass.join(' ')}>{data.displayText}</h1>
              <div className='CategoryPage-perex perex'>
                <PuxWysiwyg content={data.categoryPageDescription} />
              </div>
            </div>
            <WidgetBuilder widgetBuilderData={widgets.categoryWidgets} />
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default CategoryPage
