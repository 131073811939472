import './PricingPage.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, Link } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxAnchor from 'Shared/components/PuxAnchor/PuxAnchor'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'
import { t } from 'ttag'

import background from '../../images/PricingPage.svg'
import AllFeaturesTable from './AllFeaturesTable'
import PlansTable, { ContentPickerType } from './PlansTable'

export interface PricingCurrencyType {
  contentType: string
  contentItemId: string
  displayText: string
  pricingCurrencySymbol: string
  pricingCurrencyPriceEssential: string
  pricingCurrencyPriceBusiness: string
  pricingCurrencyPricePlatform: string
}

export interface PricingFeatureType {
  contentType: string
  contentItemId: string
  displayText: string
  pricingFeatureTooltip: string
  pricingFeaturePlanEssential: boolean
  pricingFeaturePlanBusiness: boolean
  pricingFeaturePlanPlatform: boolean
  pricingFeaturePlanEnterprise?: boolean
}

export interface PricingFeatureCategoryType {
  displayText: string
  contentType: string
  contentItemId: string
  pricingFeatureCategoryItems: {
    contentItems: [PricingFeatureType]
  }
}
export interface PricingType {
  displayText: string
  contentItemId: string
  contentItemVersionId: string
  pricingPagePerex: PuxWysiwygHtmlBodyType
  localization: {
    localizations: localizedPathType[]
  }
  pricingPageEnterpriseContent: PuxWysiwygHtmlBodyType
  pricingPageCurrencies: {
    contentItems: [PricingCurrencyType]
  }
  pricingPageFeatures: {
    contentItems: [PricingFeatureType]
  }
  pricingPageAllFeatures: {
    contentItems: [PricingFeatureCategoryType]
  }
  pricingPageBuyLink: ContentPickerType
}

export interface PricingPageType {
  data: {
    orchard: {
      easySoftwarePricingPage: [PricingType]
    }
  }
  pageContext: {
    isoCode: string
    metaTags: PuxMetaTagsType
    widgets: {
      midWidgetZone: [WidgetPuxSectionType]
      bottomWidgetZone: [WidgetPuxSectionType]
    }
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwarePricingPage(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwarePricingPage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        pricingPagePerex {
          html
        }
        pricingPageEnterpriseContent {
          html
        }
        pricingPageCurrencies {
          contentItems {
            ... on Orchard_EasySoftwarePricingCurrency {
              contentType
              contentItemId
              displayText
              pricingCurrencySymbol
              pricingCurrencyPriceEssential
              pricingCurrencyPriceBusiness
              pricingCurrencyPricePlatform
            }
          }
        }
        pricingPageFeatures {
          contentItems {
            ... on Orchard_EasySoftwarePricingFeature {
              contentType
              contentItemId
              displayText
              pricingFeatureTooltip
              pricingFeaturePlanPlatform
              pricingFeaturePlanEssential
              pricingFeaturePlanBusiness
            }
          }
        }
        pricingPageAllFeatures {
          contentItems {
            ... on Orchard_EasySoftwarePricingFeatureCategory {
              displayText
              contentItemId
              contentType
              pricingFeatureCategoryItems {
                contentItems {
                  ... on Orchard_EasySoftwarePricingFeature {
                    displayText
                    contentItemId
                    contentType
                    pricingFeaturePlanBusiness
                    pricingFeaturePlanEnterprise
                    pricingFeaturePlanEssential
                    pricingFeaturePlanPlatform
                    pricingFeatureTooltip
                  }
                }
              }
            }
          }
        }
        pricingPageBuyLink {
          internal
          text
          url
        }
      }
    }
  }
`

const PricingPage: FunctionComponent<PricingPageType> = (props) => {
  const pageData =
    props.data.orchard.easySoftwarePricingPage &&
    props.data.orchard.easySoftwarePricingPage[0],
    currencies =
      pageData.pricingPageCurrencies &&
      pageData.pricingPageCurrencies.contentItems &&
      pageData.pricingPageCurrencies.contentItems.length &&
      pageData.pricingPageCurrencies.contentItems,
    features =
      pageData.pricingPageFeatures &&
      pageData.pricingPageFeatures.contentItems &&
      pageData.pricingPageFeatures.contentItems.length &&
      pageData.pricingPageFeatures.contentItems,
    allFeatures =
      pageData.pricingPageAllFeatures &&
      pageData.pricingPageAllFeatures.contentItems &&
      pageData.pricingPageAllFeatures.contentItems.length &&
      pageData.pricingPageAllFeatures.contentItems

  const midWidgets = props.pageContext.widgets.midWidgetZone
  const bottomWidgets = props.pageContext.widgets.bottomWidgetZone

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={pageData.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='PricingPage'>
          <div className='PricingPage-header'>
            <img className='PricingPage-background' src={background} />
            <div className='Container'>
              <h1 className='PricingPage-title'>{pageData.displayText}</h1>
              <div className='PricingPage-perex perex'>
                <PuxWysiwyg content={pageData.pricingPagePerex} />
              </div>
            </div>
          </div>
          <div className='Container PricingPage-table'>
            <PlansTable
              currencies={currencies}
              features={features}
              enterpriseContent={pageData.pricingPageEnterpriseContent}
              buyLink={pageData.pricingPageBuyLink}
              locale={props.pageContext.isoCode}
            />
            <div className='PricingPage-comparison'>
              <Link
                to='#comparison'
                className='Button Button--blue Button--link Button--medium Button--scrollDown'
              >
                <h4>{t`Redmine.Pricing.ComparisonDetail`}</h4>
              </Link>
            </div>
          </div>

          <div className='Container'>
            <div className='PricingPage-mid-widgets'>
              <WidgetBuilder widgetBuilderData={midWidgets} />
            </div>

            <PuxAnchor id='comparison'>
              <div className='widget-section section-grey'>
                <div className='PricingPage-features pux-container'>
                  <h2 className='PricingPage-features-title'>
                    {t`Redmine.Pricing.CompleteFeatures`}
                  </h2>
                  <AllFeaturesTable allFeatures={allFeatures} />
                </div>
              </div>
            </PuxAnchor>

            <div className='PricingPage-bottom-widgets'>
              <WidgetBuilder widgetBuilderData={bottomWidgets} />
            </div>
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default PricingPage
