import './FunctionDetail.scss'
import '../../components/PuxRepeater/PuxRepeaterParts/FunctionPart.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent, lazy, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { PuxVideoType } from 'Shared/components/PuxVideo/PuxVideo'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

const PuxLightboxGallery = lazy(
  () => import(`Shared/components/PuxLightboxGallery/PuxLightboxGallery`)
)
const PuxVideo = lazy(() => import(`Shared/components/PuxVideo/PuxVideo`))

export interface FunctionType {
  contentType: string
  path: string
  contentItemId: string
  contentItemVersionId: string
  localization: {
    localizations: localizedPathType[]
  }
  displayText: string
  functionPerex: PuxWysiwygHtmlBodyType
  functionContent: PuxWysiwygHtmlBodyType
  functionImage: PuxMediaSelectorType
  functionGallery: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

export interface FunctionDetailType extends PageProps {
  data: {
    orchard: {
      easySoftwareFunction: [FunctionType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareFunction(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareFunction {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        functionPerex {
          html
        }
        functionContent {
          html
        }
        functionImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
            texts
          }
        }
        functionGallery {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxVideo {
          videoStructuredDataContentUrl
          videoStructuredDataDescription
          videoStructuredDataName
          videoStructuredDataUploadDate
          videoYTID {
            html
          }
        }
      }
    }
  }
`

const FunctionDetail: FunctionComponent<FunctionDetailType> = (props) => {
  const pageData = props.data.orchard.easySoftwareFunction[0]
  const breadcrumbsData = props.pageContext.breadcrumbs

  function renderMediaComponent() {
    if (
      typeof pageData.puxVideo.videoYTID?.html !== `string` ||
      pageData.puxVideo.videoYTID?.html === ``
    ) {
      return <PuxMediaSelector {...pageData.functionImage} />
    }
    if (
      typeof pageData.puxVideo.videoYTID?.html === `string` &&
      pageData.puxVideo.videoYTID?.html !== ``
    ) {
      return (
        <Suspense fallback={<></>}>
          <PuxVideo
            videoStructuredDataDescription={
              pageData.puxVideo.videoStructuredDataDescription
            }
            videoStructuredDataContentUrl={
              pageData.puxVideo.videoStructuredDataContentUrl
            }
            videoStructuredDataName={pageData.puxVideo.videoStructuredDataName}
            videoStructuredDataUploadDate={
              pageData.puxVideo.videoStructuredDataUploadDate
            }
            videoThumbnail={pageData.functionImage.mediaSelectorImage}
            videoYTID={pageData.puxVideo.videoYTID}
          />
        </Suspense>
      )
    }

    return null
  }

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={pageData.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='Container'>
          <div className='FunctionPage'>
            <EasySoftwareBreadcrumbs items={breadcrumbsData} />
            <h1 className={`FunctionPage-title`}>{pageData.displayText}</h1>
            <div className={`FunctionPage-perex perex`}>
              <PuxWysiwyg content={pageData.functionPerex} />
            </div>
            <div className='Function'>
              <div className='Function-image'>
                <div className='Function-mainImage'>
                  {renderMediaComponent()}
                  <Suspense fallback={<></>}>
                    <PuxLightboxGallery {...pageData.functionGallery} />
                  </Suspense>
                </div>
              </div>

              <div className='Function-content'>
                <div className={`FunctionPage-content`}>
                  <PuxWysiwyg content={pageData.functionContent} />
                </div>
              </div>
            </div>
            <WidgetBuilder
              widgetBuilderData={props.pageContext?.widgets?.widgetZone}
            />
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default FunctionDetail
