import './FaqDetail.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import PuxContent from 'Root/Shared/components/PuxContent/PuxContent'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface FaqType {
  contentType: string
  path: string
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
  faqAnswer: PuxWysiwygHtmlBodyType
}

export interface FunctionDetailType extends PageProps {
  data: {
    orchard: {
      easySoftwareFaq: [FaqType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareFaq(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareFaq {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        faqAnswer {
          html
        }
      }
    }
  }
`

const FaqDetail: FunctionComponent<FunctionDetailType> = (props) => {
  const pageData = props.data.orchard.easySoftwareFaq[0]
  const breadcrumbsData = props.pageContext.breadcrumbs

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={pageData.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='Container'>
          <div className={`FaqDetail`}>
            <EasySoftwareBreadcrumbs items={breadcrumbsData} />
            <PuxContent
              contentModifier='faqDetail'
              sideCards={props.pageContext.sideCardWidgets}
            >
              <h1 className={`FaqDetail-title`}>{pageData.displayText}</h1>
              <PuxWysiwyg content={pageData.faqAnswer} />
            </PuxContent>
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default FaqDetail
