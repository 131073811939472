import "./EasySoftwareHeroBannerContainer.scss";

import React, { FunctionComponent } from "react";

import EasySoftwareHeroBannerItemPicture, {EasySoftwareHeroBannerItemPictureType } from "./EasySoftwareHeroBannerItemPicture";
import EasySoftwareHeroBannerItemVideo, {EasySoftwareHeroBannerItemVideoType} from "./EasySoftwareHeroBannerItemVideo";
import EasySoftwareHeroBannerItemText, {EasySoftwareHeroBannerItemTextType} from "./EasySoftwareHeroBannerItemText";
import PuxMediaSelector, { PuxMediaSelectorType } from "Root/Shared/components/content/mediaImage/PuxMediaSelector";



// TODO: contentItems: any
export interface EasySoftwareHeroBannerContainerType {
  displayText: string;
  heroBannerContainerTheme: string;
  heroBannerContainerBackground: {
    contentItems: [
      {
        heroBannerBackgroundImage: PuxMediaSelectorType
      }
    ]
  };
  heroBannerContainerItems: {
    contentItems: [
        EasySoftwareHeroBannerItemPictureType | EasySoftwareHeroBannerItemTextType | EasySoftwareHeroBannerItemVideoType
    ]
  };
}

export type HeroBannerContainerTheme = `HeroBannerContainer--middleTheme` | `HeroBannerContainer--darkTheme`;

const EasySoftwareHeroBannerContainer: FunctionComponent<EasySoftwareHeroBannerContainerType> = (props) => (
  <div className={`HeroBannerContainer ${props.heroBannerContainerTheme ?? ``}`}>
<div className="HeroBannerContainer-background">
<PuxMediaSelector
        {...props.heroBannerContainerBackground?.contentItems[0]
          ?.heroBannerBackgroundImage}
        width={1920}
        height={600}
        disableLazyload={true}
      /> 
</div>

  
      {props.heroBannerContainerItems &&
        props.heroBannerContainerItems.contentItems &&
        props.heroBannerContainerItems.contentItems.map((item: any) => {
          const innerClass = `HeroBannerContainer-inner HeroBannerContainer-inner--${item.contentType.replace(`EasySoftwareHeroBannerItem`, ``)}`;
          switch (item.contentType) {
            case `EasySoftwareHeroBannerItemPicture`:
              return (
                <div className={innerClass}>
                <EasySoftwareHeroBannerItemPicture
                  data={item}
                  key={item.contentItemId}
                />
                    </div>
              );
            case `EasySoftwareHeroBannerItemVideo`:
              return (
                <div className={innerClass}>
                <EasySoftwareHeroBannerItemVideo
                  data={item}
                  key={item.contentItemId}
                />
                     </div>
              );
            case `EasySoftwareHeroBannerItemText`:
              return (
                <div className={innerClass}>
                <EasySoftwareHeroBannerItemText
                  data={item}
                  key={item.contentItemId}
                />
                   </div>
              );

            default:
              return ``;
          }
        })}


  </div>
);

export default EasySoftwareHeroBannerContainer;
