import PuxNewsletterForm from 'EasyProjectGatsby/components/PuxNewsletterForm/PuxNewsletterForm'
import React, { FunctionComponent, useState } from 'react'
import { Helmet } from 'react-helmet'
import { StickyContainer } from 'react-sticky'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import getHrefLangsForHelmet from 'Shared/utils/getHreflangsForHelmet'
import { getFromConfig, getLocalizedData } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import MauticInit from '../MauticForms/MauticInit'
import Footer from './footer'
import Header from './header'
import { HeaderData } from './HeaderNav'

interface LayoutPropsType {
  customLayoutClass?: string
  style?: string
  cta?: LinkType
  landingPageNav?: HeaderData
  localizedPath?: localizedPathType[]
}

const Layout: FunctionComponent<LayoutPropsType> = (props) => {
  const [customHeaderClass, setCustomHeaderClass] = useState(``)
  const getHeaderCustomClass = (duplicatedNav: boolean): void => {
    setCustomHeaderClass(duplicatedNav ? `has-megamenu` : ``)
  }

  const classes = [`layout-main`, props.customLayoutClass, customHeaderClass]
  const hreflangs = getHrefLangsForHelmet(props.localizedPath ?? [])

  const { mautic } = getLocalizedData(
    process.env.LOCALE || ``,
    process.env.PROJECT || ``
  )

  MauticInit(mautic.src, mautic.domain, t`Mautic.Submitting.Message`)

  return (
    <>
      <Helmet>
        {hreflangs?.map((hreflang) => {
          return (
            <link
              key={hreflang.isoCode}
              rel='alternate'
              hrefLang={hreflang.isoCode}
              href={hreflang.path}
            />
          )
        })}
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={process.env.GATSBY_WEBSITE_URL + `/apple-touch-icon.png`}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={process.env.GATSBY_WEBSITE_URL + `/favicon-32x32.png`}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={process.env.GATSBY_WEBSITE_URL + `/favicon-16x16.png`}
        />
        <link
          rel='manifest'
          href={process.env.GATSBY_WEBSITE_URL + `/site.webmanifest`}
        />
        <link
          rel='mask-icon'
          href={process.env.GATSBY_WEBSITE_URL + `/safari-pinned-tab.svg`}
          color='#0080ff'
        />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
        <body className={process.env.LOCALE !== `cs` ? `ENUS` : `CSCZ`} />
      </Helmet>
      <StickyContainer>
        <Header
          customHeaderFnc={getHeaderCustomClass}
          customHeaderClass={props.customLayoutClass}
          style={props.style}
          cta={props.cta}
          landingPageNav={props.landingPageNav}
          localizedPaths={props.localizedPath}
        />
        <main className={classes.join(` `)}>{props.children}</main>
        <PuxNewsletterForm />
        <Footer />
      </StickyContainer>
    </>
  )
}

export default Layout
