import './ArticlePage.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface ArticleType {
  contentType: string
  contentItemId: string
  contentItemVersionId: string
  path: string
  displayText: string
  articlePerex: PuxWysiwygHtmlBodyType
  articleContent: PuxWysiwygHtmlBodyType
  localization: {
    localizations: localizedPathType[]
  }
}

export interface ArticlePageType extends PageProps {
  data: {
    orchard: {
      easySoftwareArticle: [ArticleType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      articleWidgets: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareArticle(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareArticle {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        articlePerex {
          html
        }
        articleContent {
          html
        }
      }
    }
  }
`

const ArticlePage: FunctionComponent<ArticlePageType> = (props) => {
  const data = props.data.orchard.easySoftwareArticle[0]
  const widgetsData = props.pageContext.widgets
  const sideCardWidgetData = props.pageContext.sideCardWidgets
  const breadCrumbsData = props.pageContext.breadcrumbs

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={data.localization?.localizations}>
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className='ArticlePage Container'>
          <EasySoftwareBreadcrumbs items={breadCrumbsData} />
          <PuxContent
            contentModifier='articlePage'
            sideCards={sideCardWidgetData}
          >
            {data.displayText.trim().length && (
              <h1 className='ArticlePage-title'>{data.displayText}</h1>
            )}

            <PuxWysiwyg
              className='ArticlePage-perex perex'
              content={data.articlePerex}
            />

            <PuxWysiwyg
              className='ArticlePage-content'
              content={data.articleContent}
            />
            <WidgetBuilder widgetBuilderData={widgetsData.articleWidgets} />
          </PuxContent>
        </div>
      </Layout>
    </Suspense>
  )
}

export default ArticlePage
