import './PuxNumber.scss'

import React, { FunctionComponent } from 'react'
import { getIsoCodesWithPrefixPublic } from 'Shared/utils/localeURL'

import Circle from '../helper/Circle'

export interface PuxNumberType {
  numberDescription: string
  numberPrefix: string
  numberUnit: string
  numberValue: number
  numberShowProgress: boolean
  contentItemId: string
  numberRadius: number
  numberStroke: number
}

const PuxNumber: FunctionComponent<PuxNumberType> = (props) => {
  const IsoCode =
    getIsoCodesWithPrefixPublic().filter(
      (iso) => iso.locale === process.env.LOCALE
    )[0].isoCode ?? `en-GB`

  const prefix = `${props.numberPrefix ? props.numberPrefix + ` ` : ``}`
  const unit = `${props.numberUnit ? props.numberUnit : ``}`
  const progressNumber = prefix + props.numberValue + unit
  const formatedNumber = prefix + new Intl.NumberFormat(IsoCode).format(props.numberValue) + unit

  return (
    <div className='Number'>
      {props.numberShowProgress && (
        <div className='Number-progress'>
          <Circle
            customClass={`Number-progressBackground`}
            progress={100}
            radius={props.numberRadius}
            stroke={props.numberStroke}
          />
          <Circle
            customClass={`Number-progressMain`}
            progress={props.numberValue}
            radius={props.numberRadius}
            stroke={props.numberStroke}
          />
          <p className='Number-progressValue'>{progressNumber}</p>
        </div>
      )}
      {!props.numberShowProgress && (
        <p className='Number-value'>{formatedNumber}</p>
      )}
      <p className='Number-description'>
        {props.numberDescription ? props.numberDescription : ``}
      </p>
    </div>
  )
}

export default PuxNumber
