import '../../../Shared/templates/ErrorPage/ErrorPage.scss'

import { graphql, Link, PageProps } from 'gatsby'
import * as React from 'react'
import { FunctionComponent } from 'react'
import { PuxWysiwyg } from 'Shared/components'
import getBrowserLang from 'Shared/utils/getBrowserLang'
import PuxMetaTags from 'Shared/utils/PuxMetaTags'

import { puxErrorPageType } from '../../../Shared/templates/ErrorPage/ErrorPage'
import Layout from '../components/core/Layout'
import { ReactComponent as NotFound } from '../svg/404.svg'

const rootClass = `pux-notfound`

export const query = graphql`
  query {
    orchard {
      puxErrorPage(where: { path_contains: "404" }) {
        localization {
          culture
        }
        htmlBody {
          html
        }
        puxMetaTags {
          metaTagsCanonicalUrl {
            html
          }
          metaTagsDescription {
            html
          }
          metaTagsImage {
            resizePaths(width: 1200, mode: "Min")
          }
          metaTagsKeywords {
            html
          }
          metaTagsOGDescription {
            html
          }
          metaTagsOGImage {
            resizePaths(width: 1200, mode: "Min")
          }
          metaTagsOGTitle {
            html
          }
          metaTagsRobots
          metaTagsTitle {
            html
          }
        }
      }
    }
  }
`

const page404: FunctionComponent<puxErrorPageType> = (props) => {
  const pageData = props.data.orchard.puxErrorPage.filter((page) =>
    page.localization.culture.startsWith(getBrowserLang())
  )

  if (!pageData.length) {
    const defaultData = props.data.orchard.puxErrorPage.filter((page) =>
      page.localization.culture.includes(`en-GB`)
    )

    pageData.push(defaultData[0])
  }

  const localizedData = pageData[0]

  return (
    <Layout>
      <PuxMetaTags {...localizedData.puxMetaTags} />
      <div className={`${rootClass}`}>
        <NotFound />
        <PuxWysiwyg content={localizedData.htmlBody} />
      </div>
    </Layout>
  )
}

export default page404
