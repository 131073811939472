import './PuxSection.scss'

import React, { FunctionComponent } from 'react'
import WidgetBuilder, {
  WidgetData,
  WidgetProperitesPart,
} from 'Shared/components/builders/WidgetBuilder'
import PuxModal from 'Shared/components/PuxModal/PuxModal'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export type SectionHorizontalAlign =
  | 'Section--horizontalCenter'
  | 'Section--horizontalBottom'

export type SectionVerticalAlign = 'Section--verticalCenter'

export interface PuxSectionType {
  contentType: 'PuxSection'
  contentItemId?: string
  sectionUseContainer: boolean
  sectionPerex: PuxWysiwygHtmlBodyType
  sectionHorizontalAlign: SectionHorizontalAlign
  sectionVerticalAlign: SectionVerticalAlign
  sectionIsModal?: boolean
  sectionModalId?: string
  sectionButton: PuxButtonType
  noSpacing: boolean
  template: [
    {
      contentItemId: string
    }
  ]
  puxBackgroundDefinition: {
    backgroundDefinitionPartColor: 'Section--light'
  }
  flow: {
    widgets: [WidgetData]
  }
  puxWidgetProperties: WidgetProperitesPart
}

const PuxSection: FunctionComponent<PuxSectionType> = (props) => {
  const { template } = props

  if (props.template?.length > 1) {
    props.flow.widgets.sort((widget1, widget2) => {
      const index1 = template.findIndex(
        (x) => x.contentItemId === widget1.contentItemId
      )
      const index2 = template.findIndex(
        (x) => x.contentItemId === widget2.contentItemId
      )
      return index1 - index2
    })
  }

  const widgetProperties = props.puxWidgetProperties
  const classes: string[] = [`Section`]
  const containerClasses: string[] = [`Container`]
  const ids: string[] = []

  if (props.puxBackgroundDefinition?.backgroundDefinitionPartColor) {
    classes.push(props.puxBackgroundDefinition.backgroundDefinitionPartColor)
  }

  if (widgetProperties.widgetPropertiesPartMarginBottom) {
    classes.push(widgetProperties.widgetPropertiesPartMarginBottom)
  }

  if (widgetProperties.widgetPropertiesPartMarginTop) {
    classes.push(widgetProperties.widgetPropertiesPartMarginTop)
  }

  if (widgetProperties.widgetPropertiesPartPaddingBottom) {
    classes.push(widgetProperties.widgetPropertiesPartPaddingBottom)
  }

  if (widgetProperties.widgetPropertiesPartPaddingTop) {
    classes.push(widgetProperties.widgetPropertiesPartPaddingTop)
  }

  if (!props.sectionUseContainer) {
    containerClasses.push(`Container--full`)
  }

  if (widgetProperties.widgetPropertiesPartID)
    ids.push(widgetProperties.widgetPropertiesPartID)

  if (props.sectionHorizontalAlign) classes.push(props.sectionHorizontalAlign)

  if (props.sectionVerticalAlign) classes.push(props.sectionVerticalAlign)

  const content = (
    <section className={classes.join(` `)} id={ids.join(` `)}>
      <div className={containerClasses.join(` `)}>
        {props.sectionPerex?.html && (
          <PuxWysiwyg className='Section-perex' content={props.sectionPerex} />
        )}
        <div className='Section-grid'>
          <WidgetBuilder widgetBuilderData={props.flow.widgets} />
        </div>
        {props.sectionButton?.buttonLink.text[0] && (
          <div className='Section-button'>
            <PuxButton {...props.sectionButton} />
          </div>
        )}
      </div>
    </section>
  )

  if (
    props.sectionIsModal &&
    typeof props.sectionModalId === `string` &&
    props.sectionModalId.length > 0
  ) {
    return <PuxModal id={props.sectionModalId}>{content}</PuxModal>
  } else {
    return content
  }
}

export default PuxSection
