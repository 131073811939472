import './LandingPage.scss'

import { HeaderData } from '@componentsfld/core/HeaderNav'
import Layout from '@componentsfld/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import { LinkType } from 'Shared/types/Link'
import {
  AnchorItemType,
  LinkItemType,
  menuItemsMapper,
} from 'Shared/utils/menuItemsMapper'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface LandingType {
  displayText: string
  contentType: string
  contentItemId: string
  path: string
  landingPageButton: LinkType
  landingPageAnchors: {
    contentItems: (AnchorItemType | LinkItemType)[]
  }
  easySoftwareOptimizeSettings?: {
    optimizeSettingsAntiFlicker?: boolean
    optimizeSettingsContainer?: string
  }
}

export interface LandingPageType {
  data: {
    orchard: {
      puxLandingPage: [LandingType]
    }
  }
  pageContext: {
    isoCode: string
    metaTags: PuxMetaTagsType
    widgets: {
      landingWidgets: [WidgetPuxSectionType]
    }
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxLandingPage(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        landingPageButton {
          text
          url
        }
        landingPageAnchors {
          contentItems {
            ... on Orchard_EasySoftwareAnchorItem {
              displayText
              contentType
              anchorItemTarget
            }
            ... on Orchard_EasySoftwareLinkItem {
              contentType
              linkItemTarget {
                internal
                text
                url
              }
            }
          }
        }
        easySoftwareOptimizeSettings {
          optimizeSettingsAntiFlicker
          optimizeSettingsContainer
        }
      }
    }
  }
`

const rootClass = `landing-page`

const LandingPage: FunctionComponent<LandingPageType> = (props) => {
  const pageData =
    props.data.orchard.puxLandingPage && props.data.orchard.puxLandingPage[0]

  const widgetZone = props.pageContext.widgets.landingWidgets

  const headerData: HeaderData = {
    menuItems: menuItemsMapper(pageData?.landingPageAnchors?.contentItems),
  }

  return (
    <Suspense fallback={<></>}>
      <Layout
        style='landing'
        cta={pageData?.landingPageButton}
        landingPageNav={headerData}
      >
        {pageData.easySoftwareOptimizeSettings &&
          pageData.easySoftwareOptimizeSettings.optimizeSettingsAntiFlicker &&
          pageData.easySoftwareOptimizeSettings.optimizeSettingsContainer && (
            <Helmet>
              <style>{`.async-hide {opacity: 0 !important}`}</style>
              <script>{`
            (function(a,s,y,n,c,h,i,d,e){s.className += ' ' + y;h.start=1*new Date;
            h.end=i=function(){s.className = s.className.replace(RegExp(' ?' + y), '')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
            })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'${pageData.easySoftwareOptimizeSettings.optimizeSettingsContainer}':true});`}</script>
            </Helmet>
          )}
        <PuxMetaTags {...props.pageContext.metaTags} />
        <div className={rootClass}>
          <div className={`${rootClass}-widgets`}>
            <WidgetBuilder widgetBuilderData={widgetZone} />
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default LandingPage
