import { useLocation } from '@reach/router'
import { useHeaderData } from 'EasyProjectGatsby/queries/_use-header-data.js'
import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import EasySoftwareSearch from 'Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import PuxButton from 'Shared/components/PuxButton/PuxButton'
import PuxLanguageSelector, {
  localizedPathType,
} from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import { getFromConfig } from 'Shared/utils/localeURL'
import { t } from 'ttag'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'

import { ReactComponent as Logo } from '../../svg/EP-logo.svg'
import HeaderNav, { HeaderData } from './HeaderNav'
import HeaderMobileNav from './HeaderMobileNav'

const localizedHomepageUrl = `/${getFromConfig(
  process.env.LOCALE ?? `en`,
  `urlPrefix`,
  `EasyProjectGatsby`
)}`

interface HeaderPropsType {
  customHeaderClass?: string
  customHeaderFnc: (duplicatedNav: boolean) => void
  style?: string
  cta?: LinkType
  landingPageNav?: HeaderData
  localizedPaths?: localizedPathType[]
}

const Header: React.FunctionComponent<HeaderPropsType> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isScrolled, setScrolled] = React.useState(false)
  const headerData: HeaderData = useHeaderData()
  const { width } = useWindowDimensions()
  const localizedPaths = props.localizedPaths
  const isDesktop = width > 992 || width === 0
  const isRespo = width <= 991 && width !== 0

  const classList = [`Header`]

  if (isOpen) classList.push(`is-opened`)
  if (isScrolled) classList.push(`is-scrolled`)
  if (props.style === `landing`) classList.push(`Header--landing`)
  if (props.customHeaderClass) classList.push(props.customHeaderClass)

  const updateDimensions = () => {
    if (window.scrollY > 0) {
      return setScrolled(true)
    }

    return setScrolled(false)
  }

  useEffect(() => {
    window.addEventListener(`scroll`, updateDimensions)

    return () => {
      window.removeEventListener(`scroll`, updateDimensions)
    }
  }, [isScrolled])

  return (
    <header className={classList.join(` `)}>
      <div className='Header-container'>
        <Link className='Header-logo' to={localizedHomepageUrl}>
          <Logo />
        </Link>
        {isDesktop && (
          <HeaderNav
            headerData={props.landingPageNav ? props.landingPageNav : headerData}
          />
        )}
        <div className='Header-trial'>
          <PuxButton
            buttonType='Button--primary'
            buttonSize='Button--medium'
            buttonColor='Button--yellow'
            buttonLink={{
              url: [props.cta ? props.cta.url : `#trial`],
              internal: [false],
              text: [props.cta ? props.cta.text : t`Redmine.Header.FreeTrial`],
            }}
          />
        </div>
        {isDesktop && (
          <>
            <a
              href='https://client-zone.easysoftware.com/en/login'
              className={`Header-user`}
            />
            <div className={`Header-search`}>
              <EasySoftwareSearch
                searchType='header'
              />
            </div>
            <div className={`Header-language`}>
              <PuxLanguageSelector
                localizedPaths={localizedPaths}
              />
            </div>
          </>
        )}
        {isRespo && (
          <div className={`Header-burger`} onClick={() => setIsOpen(!isOpen)}>
            <span className='Header-burgerIcon Header-burgerIcon--open'></span>
            <span className='Header-burgerIcon Header-burgerIcon--close'></span>
          </div>
        )}
      </div>
      {isRespo && (
        <div className='Header-respoWrapper'>
          <EasySoftwareSearch
            searchType='headerRespo'
          />
          <HeaderMobileNav
            headerData={props.landingPageNav ? props.landingPageNav : headerData}
          />
          <div className='Header-respoWrapperFooter'>
            <a
              href='https://client-zone.easysoftware.com/en/login'
              className={`Header-user`}
            />
            <div className={`Header-language`}>
              <PuxLanguageSelector
                localizedPaths={localizedPaths}
                selectorType='nativeDropdown'
              />
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
