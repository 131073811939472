import './KnowledgeBase.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareSearch from 'Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareKnowledgeBase(first: 1, where: { path: $pagePath }) {
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareKnowledgeBase {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

interface PageType {
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

interface KnowledgeBaseType {
  data: {
    orchard: {
      easySoftwareKnowledgeBase: [PageType]
    }
  }
  pageContext: {
    //breadcrumbs: [BreadcrumbItem]
    metaTags: PuxMetaTagsType
    knowledgeBaseData: KnowledgeBaseMenuItemType[]
    //sideCardWidgets: any
  }
}

const KnowledgeBase: FunctionComponent<KnowledgeBaseType> = ({
  data,
  pageContext,
}) => {
  //const breadcrumbsData = pageContext.breadcrumbs
  return (
    <Suspense fallback={<></>}>
      <Layout
        localizedPath={
          data.orchard.easySoftwareKnowledgeBase[0].localization?.localizations
        }
      >
        {/* <EasySoftwareBreadcrumbs items={breadcrumbsData} /> */}
        <PuxMetaTags {...pageContext.metaTags} />
        <div className='Container KnowledgeBase'>
          <div className='KnowledgeBase-header'>
            <h1>{data.orchard.easySoftwareKnowledgeBase[0].displayText}</h1>
            <EasySoftwareSearch
              searchType='knowledgebase'
              setLanguageDialogOpen={() => true}
            />
          </div>
          <div className='KnowledgeBase-container'>
            {pageContext.knowledgeBaseData.map((item, index) => (
              <a
                className='KnowledgeBase-item'
                href={getLocalizedUrl(item.path)}
              >
                <span>
                  {index < 9 ? `0` + (index + 1).toString() : index + 1}
                </span>
                <h2>{item.displayTextWithoutNumber}</h2>
              </a>
            ))}
          </div>
        </div>
      </Layout>
    </Suspense>
  )
}

export default KnowledgeBase
