import Layout from '@componentsfld/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface HomePageType {
  localization: {
    localizations: localizedPathType[]
  }
}

interface PuxHomePageType {
  data: {
    orchard: {
      puxHomePage: [HomePageType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxHomePage(first: 1, where: { path: $pagePath }) {
        localization {
          localizations {
            ... on Orchard_PuxHomePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

const PuxHomePage: FunctionComponent<PuxHomePageType> = ({
  data,
  pageContext,
}) => {
  const structuredData = {
    '@context': `https://schema.org`,
    '@type': `WebApplication`,
    name: `Easy Redmine`,
    offers: {
      '@type': `Offer`,
      price: `78.00`,
      priceCurrency: `USD`,
    },
    aggregateRating: {
      '@type': `AggregateRating`,
      ratingValue: `4.5`,
      bestRating: `5`,
      ratingCount: `57`,
    },
    applicationCategory: `BusinessApplication`,
    sameAs: [
      `https://www.facebook.com/easyredmine/`,
      `https://twitter.com/easyredmine`,
      `https://www.youtube.com/channel/UC8lBlJxEOZ_FYZ-KZYbz5Cw`,
    ],
    image: `redminelogo.png`,
  }

  const pageData = data.orchard.puxHomePage && data.orchard.puxHomePage[0]

  return (
    <Suspense fallback={<></>}>
      <Layout localizedPath={pageData.localization?.localizations}>
        <PuxStructuredData structuredData={structuredData} />
        <PuxMetaTags {...pageContext.metaTags} />
        <WidgetBuilder widgetBuilderData={pageContext.widgets.widgetZone} />
      </Layout>
    </Suspense>
  )
}

export default PuxHomePage
