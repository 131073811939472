import './CaseStudyPart.scss'

import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import { PuxKeyValueType } from 'Shared/components/EasySoftwareCaseStudies/EasySoftwareCaseStudyItem'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'

export interface CaseStudyPartType {
  contentType: 'EasySoftwareCaseStudy'
  contentItemId: string
  path: string
  displayText: string
  caseStudyPerex: PuxWysiwygHtmlBodyType
  caseStudyImage: PuxMediaSelectorType
  caseStudyParameters: {
    contentItems: [PuxKeyValueType]
  }
}

const CaseStudyPart: FunctionComponent<CaseStudyPartType> = (props) => {
  return (
    <div className='pux-repeater-item CasestudyPart-wrapper'>
      <div className='CasestudyPart-item'>
        <div className='CasestudyPart-image'>
          <Link to={`${getLocalizedUrl(props.path)}`}>
            <PuxMediaSelector {...props.caseStudyImage} />
          </Link>
        </div>

        <div className='CasestudyPart-parameters'>
          {props.caseStudyParameters &&
            props.caseStudyParameters.contentItems &&
            props.caseStudyParameters.contentItems.map((parameter, idx) => (
              <div className='CasestudyPart-parameter' key={idx}>
                <strong className='CasestudyPart-label'>
                  {parameter.displayText}:
                </strong>
                <PuxWysiwyg inline content={parameter.keyValueContent} />
              </div>
            ))}
        </div>

        <h2 className='CasestudyPart-heading'>
          <Link to={`${getLocalizedUrl(props.path)}`}>{props.displayText}</Link>
        </h2>

        <div className='CasestudyPart-perex'>
          <PuxWysiwyg content={props.caseStudyPerex} inline />
        </div>
      </div>
    </div>
  )
}

export default CaseStudyPart
