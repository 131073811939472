import './easySoftwarePage.scss'

import Layout from '@componentsfld/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent, Suspense } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
  breadcrumbsOnTopImage,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwarePage(first: 1, where: { path: $pagePath }) {
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwarePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

interface PageType {
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

interface EasySoftwarePageType {
  data: {
    orchard: {
      easySoftwarePage: [PageType]
    }
  }
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
  }
}

const EasySoftwarePage: FunctionComponent<EasySoftwarePageType> = ({
  data,
  pageContext,
}) => {
  const breadcrumbsData = pageContext.breadcrumbs
  return (
    <Suspense fallback={<></>}>
      <Layout
        customLayoutClass={
          breadcrumbsOnTopImage(pageContext.widgets.widgetZone) &&
            breadcrumbsData?.length
            ? `has-topimage`
            : ``
        }
        localizedPath={
          data.orchard.easySoftwarePage[0].localization?.localizations
        }
      >
        <div className="EasySoftwarePage">
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <PuxMetaTags {...pageContext.metaTags} />
        <WidgetBuilder widgetBuilderData={pageContext?.widgets?.widgetZone} />
        </div>
      </Layout>
    </Suspense>
  )
}

export default EasySoftwarePage
