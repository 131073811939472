import './ArticlePart.scss'

import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxVideo, { PuxVideoType } from 'Shared/components/PuxVideo/PuxVideo'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'

export interface ArticlePartType {
  contentType: 'EasySoftwareArticle'
  contentItemId: string
  path: string
  displayText: string
  articlePerex: PuxWysiwygHtmlBodyType
  articleImage: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

const ArticlePart: FunctionComponent<ArticlePartType> = (props) => {
  function renderMediaComponent() {
    if (
      typeof props.puxVideo.videoYTID?.html !== `string` ||
      props.puxVideo.videoYTID?.html === ``
    ) {
      return (
        <Link to={`${getLocalizedUrl(props.path)}`}>
          <PuxMediaSelector {...props.articleImage} />
        </Link>
      )
    }
    if (
      typeof props.puxVideo.videoYTID?.html === `string` &&
      props.puxVideo.videoYTID?.html !== ``
    ) {
      return (
        <PuxVideo
          videoStructuredDataDescription={
            props.puxVideo.videoStructuredDataDescription
          }
          videoStructuredDataContentUrl={
            props.puxVideo.videoStructuredDataContentUrl
          }
          videoStructuredDataName={props.puxVideo.videoStructuredDataName}
          videoStructuredDataUploadDate={
            props.puxVideo.videoStructuredDataUploadDate
          }
          videoThumbnail={props.articleImage.mediaSelectorImage}
          videoYTID={props.puxVideo.videoYTID}
        />
      )
    }

    return null
  }

  return (
    <div className={`pux-repeater-item ArticlePart-wrapper`}>
      <div className='ArticlePart'>
        <div className='ArticlePart-items'>
          <h2>
            <Link to={`${getLocalizedUrl(props.path)}`}>
              {props.displayText}
            </Link>
          </h2>
          <div className='ArticlePart-content'>
            <div className='ArticlePart-perex'>
              <PuxWysiwyg content={props.articlePerex} />
            </div>
            <div className='ArticlePart-image'>{renderMediaComponent()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticlePart
